<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <header-image :page-no="pageId" :base-url="fileUrl" :bg-image="bgImage" :key="bgImage"/>
        <h3>Manage Posts</h3>
        <button class="btn btn-behance" @click="modal('addPost')"><i class="material-icons">add</i> Click here to Add Post</button>
        <br>
        <br>
        <div class="row">
          <div class="col-md-4" v-for="post in posts">
            <div class="card card-product">
              <div class="card-header card-header-image" data-header-animation="true">
                <a href="#pablo">
                  <img class="img" :src="baseUrl + post.image" style="max-height: 533px">
                </a>
              </div>
              <div class="card-body">
                <div class="card-actions text-center">
                  <button type="button" class="btn btn-success btn-link" rel="tooltip" data-placement="bottom" title="Edit" @click="modal('editPost', post)">
                    <i class="material-icons">edit</i>
                  </button>
                  <button type="button" class="btn btn-success btn-link" rel="tooltip" data-placement="bottom" title="Publish" @click="modal('publishPost', post)">
                    <i class="material-icons">published_with_changes</i>
                  </button>
                  <button type="button" class="btn btn-danger btn-link" rel="tooltip" data-placement="bottom" title="Delete" @click="modal('deletePost', post)">
                    <i class="material-icons">delete_forever</i>
                  </button>
                </div>
                <h4 class="card-title">
                  <a href="javascript:void(0)" v-text="post.image"></a>
                </h4>
              </div>
              <div class="card-footer">
                <div class="price">
                  <h4 v-if="post.status === '0'">Date Created</h4>
                  <h4 v-else class="text-success">Date Published</h4>
                </div>
                <div class="stats">
                  <p class="card-category"><i class="material-icons">timer</i> <span v-text="post.created"></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal | Choose from gallery -->
    <div class="ui modal" id="postFromGallery">
      <i class="close icon"></i>
      <div class="header">
        Choose Image
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-4 py-2" v-for="img in images.slice(0, -2)" @click="selectImage(img)">
            <img class="img-fluid" :src="baseUrl + img">
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
      </div>
    </div>

    <!-- Modal | Add Post -->
    <div class="ui modal" id="addPost">
      <i class="close icon"></i>
      <div class="header">
        Add Post
      </div>
      <div class="content">
        <div class="ui grid">
          <div class="column">
            <div class="ui form">
              <div class="equal width fields">
                <div class="field">
                  <label>Post Heading</label>
                  <div class="ui fluid input">
                    <input type="text" placeholder="" v-model="heading">
                  </div>
                </div>
              </div>
              <div class="equal width fields">
                <div class="field">
                  <label>Font Size(px)</label>
                  <div class="ui fluid input">
                    <input type="number" placeholder="" v-model="fontSize">
                  </div>
                </div>
                <div class="field">
                  <label>Font Color</label>
                  <div class="ui fluid input">
                    <input type="color" placeholder="#fffff" v-model="fontColor" style="height: 38px">
                  </div>
                </div>
                <div class="field">
                  <label>Date Published</label>
                  <div class="ui fluid input">
                    <input type="datetime-local" placeholder="" v-model="created">
                  </div>
                </div>
              </div>
              <div class="field">
                <label>Choose Image Type</label>
                <select class="ui dropdown form-control search" data-width="auto" v-model="imageType">
                  <option value="upload">Upload</option>
                  <option value="gallery">Pick from Gallery</option>
                </select>
              </div>
              <div v-if="imageType === 'upload'" class="equal width fields">
                <div class="field">
                  <label>Image</label>
                  <div class="ui fluid input">
                    <input type="file" id="postImage" name="postImage">
                  </div>
                </div>
              </div>
              <div v-else class="equal width fields">
                <div class="field"><button type="button" class="btn btn-success btn-round" @click="modal('postFromGallery')">
                  <i class="material-icons">collections</i> Choose from gallery
                </button>
                  <span v-text="imageName"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div v-if="imageType === 'upload'" class="ui right labeled icon button" v-on:click="addPost">
          Save
          <i class="checkmark icon"></i>
        </div>
        <div v-else class="ui right labeled icon button" v-on:click="addPostGallery">
          Save
          <i class="checkmark icon"></i>
        </div>
        <div v-if="imageType === 'upload'" class="ui success right labeled icon button" v-on:click="addPost('publish')">
          Save & Publish
          <i class="checkmark icon"></i>
        </div>
        <div v-else class="ui success right labeled icon button" v-on:click="addPostGallery('publish')">
          Save & Publish
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>

    <!-- Modal | Edit Post -->
    <div class="ui modal" id="editPost">
      <i class="close icon"></i>
      <div class="header">
        Update Post
      </div>
      <div class="content">
        <div class="ui grid">
          <div class="column">
            <div class="ui form">
              <div class="equal width fields">
                <div class="field">
                  <label>Post Heading</label>
                  <div class="ui fluid input">
                    <input type="text" placeholder="" v-model="heading">
                  </div>
                </div>
              </div>
              <div class="equal width fields">
                <div class="field">
                  <label>Font Size(px)</label>
                  <div class="ui fluid input">
                    <input type="number" placeholder="" v-model="fontSize">
                  </div>
                </div>
                <div class="field">
                  <label>Font Color</label>
                  <div class="ui fluid input">
                    <input type="color" placeholder="#fffff" v-model="fontColor" style="height: 38px">
                  </div>
                </div>
              </div>
              <!--
              <div class="field">
                <label>Date Published</label>
                <div class="ui fluid input">
                  <input type="datetime-local" placeholder="" v-model="created">
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div class="ui right labeled icon button" v-on:click="editPost">
          Update
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>

    <!-- Modal | Publish Post -->
    <div class="ui modal" id="publishPost">
      <i class="close icon"></i>
      <div class="header">
        Publish Post
      </div>
      <div class="content">
        <div class="ui grid">
          <div class="column">
            <div class="ui form">
              <div class="field">
                <label>Date Published</label>
                <div class="ui fluid input">
                  <input type="datetime-local" placeholder="" v-model="created">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div class="ui right labeled icon button" v-on:click="publishPost">
          Publish
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>

    <!-- Modal | Delete Post -->
    <div class="ui modal small" id="deletePost">
      <i class="close icon"></i>
      <div class="header">
        Delete Post
      </div>
      <div class="content">
        <div class="ui grid">
          <div class="column">
            <div class="ui form">
              <div class="field">
                <label>Choose Deletion Type</label>
                <select class="ui dropdown form-control search" data-width="auto" v-model="file">
                  <option value="false">Post Only</option>
                  <option value="true">With Image</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div class="ui right labeled icon button" v-on:click="deletePost">
          Delete
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  components: {HeaderImage },
  data() {
    return {
      posts: [],
      images: [],
      baseUrl: '',
      fileUrl: '',
      more: 10,
      pageId: 2,
      bgImage: '',
      heading: '',
      id: null,
      fontSize: '',
      fontColor: '',
      created: '',
      commentStatus: 'disabled',
      imageType: 'upload',
      imageName: '',
      type: 'blogs',
      file: false
    };
  },
  mounted: function(){
    this.refresh();
  },
  watch: {
    type(newVal, oldVal){
      if (newVal !== oldVal){
        this.setFiles();
      }
    }
  },

  methods: {
    ...mapActions(["getPost", "getFiles", "removePost", "updatePageGallery", "postJson", "getPage", "updateContent",
      "createPost"]),
    refresh(){
      this.setPost();
      this.setFiles();
      this.setPage();
    },

    reset(){
      this.heading = '';
      this.id = null;
      this.fontColor = '';
      this.fontSize = '';
      this.created = '';
      this.imageName = '';
    },

    async setPost() {
      let app = this;
      let payload = {
        pageId: app.pageId
      };
      let response = await this.getPost(payload)
      //console.log("res: " +JSON.stringify(response))
      app.posts = response.message;
      app.baseUrl = response.url + app.type + '/';
    },

    async setFiles() {
      let app = this;
      let payload = {
        type: app.type,
      };
      app.baseUrl = app.fileUrl + app.type + '/';
      let response = await this.getFiles(payload)
      //console.log("res: " +JSON.stringify(response))
      app.images = response.message;
    },

    async deletePost(){
      let app = this;
      let dataObject = {
        id: app.id,
        file: app.file,
      };

      swal({
        title: 'Are you sure?',
        text: 'Do you want to delete this post?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        window.swal({
          title: "Deleting post...",
          text: "Please wait",
          imageUrl: "../assets/img/spinner.gif",
          showConfirmButton: false,
          allowOutsideClick: false
        });

        let response = await this.removePost(dataObject)

        if (response.status !== 200) {
          swal("Error!", response.message, "error");
        }
        else {

          $('#deletePost').modal('hide')

          swal("Success!", response.message, "success");
          app.refresh();
        }
      })

    },

    async deleteFile(imageName){
      let app = this;
      window.swal({
        title: "Deleting file...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let dataObject = {
        imageName: imageName,
        type: app.type
      };

      let response = await this.removePost(dataObject)

      if (response.status !== 200) {
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.refresh();
      }

    },

    async editPost(){
      let app = this;
      window.swal({
        title: "Editing post...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let dataObject = {
        heading: app.heading,
        fontSize: app.fontSize,
        fontColor: app.fontColor,
        created: app.created,
        id: app.id,
        route: 'post/update/',
      };

      let response = await this.postJson(dataObject)

      if (response.status !== 200) {
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.refresh();
        app.reset();
        $('#editPost').modal('hide');
      }

    },

    async publishPost(){
      let app = this;
      window.swal({
        title: "Publishing post...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let dataObject = {
        created: app.created,
        status: 'publish',
        id: app.id,
        route: 'post/update/'
      };

      let response = await this.postJson(dataObject)

      if (response.status !== 200) {
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.refresh();
        app.reset();
        $('#publishPost').modal('hide');
      }

    },

    async setPage() {
      let app = this;
      let payload = {
        id: app.pageId
      };
      let response = await this.getPage(payload)
      //console.log("res: " +JSON.stringify(response))
      app.bgImage = response.message.bgImage;
      app.baseUrl = response.url + app.type + '/';
      app.fileUrl = response.url;
    },

    async editPage (){
      window.swal({
        title: "Uploading data...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let app = this;
      let formData = new FormData();
      formData.append('id', app.pageId);

      // Attach file
      formData.append('image', $('input[type=file]')[0].files[0]);

      let response = await this.updateContent(formData)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.refresh();

        $('.fileinput').fileinput('reset');
      }
    },

    async addPost (status = null){
      let app = this;
      if (!app.heading) swal("Missing Field!", "Post heading is required", "error");

      window.swal({
        title: "Creating post...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let formData = new FormData();
      formData.append('pageId', app.pageId);
      formData.append('heading', app.heading);
      formData.append('fontSize', app.fontSize);
      formData.append('fontColor', app.fontColor);
      //formData.append('route', 'post/add/');

      if (status === 'publish'){
        if (!app.created){
          swal("Error!", "Publish date is required", "error");
          return;
        }
        formData.append('created', app.created);
        formData.append('status', status);
      }

      // Attach file
      // formData.append('image', $('input[type=file]')[0].files[0]);
      formData.append('image', document.getElementById('postImage').files[0]);

      let response = await this.createPost(formData)
      if (response.status !== 201){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.refresh();
        app.reset();

        // $('.fileinput').fileinput('reset');
        $('#addPost').modal('hide');
        document.getElementById('postImage').value = '';
      }
    },

    async addPostGallery (status = null){
      let app = this;
      if (!app.heading) {
        swal("Missing Field!", "Post heading is required", "error");
        return;
      }
      if (!app.imageName) {
        swal("Missing Field!", "Image is required", "error");
        return;
      }

      window.swal({
        title: "Creating post...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });

      let dataObject;

      dataObject = {
        pageId: app.pageId,
        heading: app.heading,
        fontSize: app.fontSize,
        fontColor: app.fontColor,
        imageName: app.imageName,
        route: 'post/gallery/'
      };

      if (status === 'publish'){
        if (!app.created){
          swal("Error!", "Publish date is required", "error");
          return;
        }

        dataObject = {
          pageId: app.pageId,
          heading: app.heading,
          fontSize: app.fontSize,
          fontColor: app.fontColor,
          created: app.created,
          status: status,
          imageName: this.imageName,
          route: 'post/gallery/'
        };

      }


      let response = await this.postJson(dataObject)
      if (response.status !== 201){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.refresh();
        app.reset();

        $('#addPost').modal('hide');
      }

    },

    selectImage: function (imageName){
      this.imageName = imageName;

      $('#postFromGallery').modal('hide');
      $('#addPost').modal('show');
    },

    modal: function (modal, data = null ){
      if (modal === 'postFromGallery'){
        $('#addPost').modal('hide');
        this.type = 'blogs'
      }
      if (modal === 'bgFromGallery') this.type = 'headers';
      $('#'+modal).modal('show');

      if (data) {
        this.id = data.id;
        this.heading = data.heading;
        this.fontColor = data.fontColor;
        this.fontSize = data.fontSize;
        this.created = data.created;
      }
    },

    addMore: function (){
      this.more = this.more + 10;
    },

    copyLink: function (id){
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);

      document.getElementById('id-'+id).innerHTML = 'Link Copied';
    }
  },
}
</script>
