<template>
  <div class="wrapper ">
    <side-bar />
    <div class="main-panel">
      <!-- Navbar -->
      <navbar />
      <!-- End Navbar -->
      <div class="content">

        <router-view></router-view>

      </div>
      <footer class="footer">
        <div class="container-fluid">
          <nav class="float-left">
            <ul>
              <li>
                <a href="https://covenantnet.com">
                  covenantnet
                </a>
              </li>
            </ul>
          </nav>
          <div class="copyright float-right">
            &copy; {{ year }}, made with <i class="material-icons">favorite</i> by
            <a href="https://halo-technology.co.ke" target="_blank">Halo Technology</a> for a better web.
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import SideBar from "@/components/aside";
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
export default {
    data(){
        return{
          year: new Date().getFullYear(),
        }
    },
    components: {
      Footer,
      Navbar,
      SideBar,
    },
}
</script>
