import Vue from 'vue'
import Router from 'vue-router'
import store from "../store/index";
import Login from '../views/login.vue'
import index from '../components'


import People from "../pages/people";
import About from "../pages/about";
import Blog from "../pages/blog";
import Magazine from "../pages/magazine";
import Donate from "../pages/donate";
import MailingList from "@/pages/mailing-list";
import Registration from "@/pages/registration";

import Project from "../pages/projects";
import Testimonies from "../pages/testimonies";
import Ministries from "../pages/ministries";
import Training from "../pages/training";

import OnlineBibleStudy from "../pages/online-courses/bible-study";
import OnlineBusiness from "../pages/online-courses/business";
import OnlineCourseStudy from "../pages/online-courses/course-study";
import OnlineCreativity from "../pages/online-courses/creativity";
import OnlineLeadership from "../pages/online-courses/leadership";
import OnlineRegistration from "../pages/online-courses/registration";

import Resources from "../pages/resources/index";
import ResourcesUnique from "../pages/resources/unique";
import ResourcesCreation from "../pages/resources/creation";
import ResourcesDaily from "../pages/resources/daily-nuggets";
import ResourcesEndTimes from "../pages/resources/end-times";
import Settings from "@/pages/settings";
import Preferences from "@/pages/preferences";
import Comments from "@/pages/comments";
import Files from "@/pages/files";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/login', name: 'login', component: Login, meta: { guest: true } },
        {
            path: '/',
            component: index,
            children: [
                { path: '', name: 'blog', component: Blog, meta: { name: 'Blog', requiresAuth: true }},
                { path: 'people', name: 'people', component: People, meta: { name: 'People', requiresAuth: true }},
                { path: 'about', name: 'about', component: About, meta: { name: 'About', requiresAuth: true }},
                { path: 'magazine', name: 'magazine', component: Magazine, meta: { name: 'Magazine', requiresAuth: true }},
                { path: 'donate', name: 'donate', component: Donate, meta: { name: 'Donate', requiresAuth: true }},
                { path: 'mailing', name: 'mailing', component: MailingList, meta: { name: 'Mailing List', requiresAuth: true }},
                { path: 'registration', name: 'registration', component: Registration, meta: { name: 'Registration Management', requiresAuth: true }},
                { path: 'settings', name: 'settings', component: Settings, meta: { name: 'Settings', requiresAuth: true }},
                { path: 'preferences', name: 'preferences', component: Preferences, meta: { name: 'Preferences', requiresAuth: true }},
                { path: 'comments', name: 'comments', component: Comments, meta: { name: 'Comments', requiresAuth: true }},
                { path: 'files', name: 'files', component: Files, meta: { name: 'Files', requiresAuth: true }},

                { path: 'project', name: 'project', component: Project, meta: { name: 'Project', requiresAuth: true }},
                { path: 'testimonies', name: 'testimonies', component: Testimonies, meta: { name: 'Testimonies', requiresAuth: true }},
                { path: 'ministries', name: 'ministries', component: Ministries, meta: { name: 'Ministries', requiresAuth: true }},
                { path: 'training', name: 'training', component: Training, meta: { name: 'Training', requiresAuth: true }},

                { path: 'online-courses/bible-study', name: 'OnlineBibleStudy', component: OnlineBibleStudy, meta: { name: 'Online Courses | Bible Study', requiresAuth: true }},
                { path: 'online-courses/business', name: 'OnlineBusiness', component: OnlineBusiness, meta: { name: 'Online Courses | Business', requiresAuth: true }},
                { path: 'online-courses/course-study', name: 'OnlineCourseStudy', component: OnlineCourseStudy, meta: { name: 'Online Courses | Course Study', requiresAuth: true }},
                { path: 'online-courses/creativity', name: 'OnlineCreativity', component: OnlineCreativity, meta: { name: 'Online Courses | Creativity', requiresAuth: true }},
                { path: 'online-courses/leadership', name: 'OnlineLeadership', component: OnlineLeadership, meta: { name: 'Online Courses | Leadership', requiresAuth: true }},
                { path: 'online-courses/registration', name: 'OnlineRegistration', component: OnlineRegistration, meta: { name: 'Online Courses | Registration', requiresAuth: true }},

                { path: 'resources', name: 'resources', component: Resources, meta: { name: 'Resources', requiresAuth: true }},
                { path: 'resources/unique', name: 'ResourcesUnique', component: ResourcesUnique, meta: { name: 'Resources | Unique', requiresAuth: true }},
                { path: 'resources/creation', name: 'ResourcesCreation', component: ResourcesCreation, meta: { name: 'Resources | Creation', requiresAuth: true }},
                { path: 'resources/daily-nuggets', name: 'ResourcesDaily', component: ResourcesDaily, meta: { name: 'Resources | Daily Nuggets', requiresAuth: true }},
                { path: 'resources/end-times', name: 'ResourcesEndTimes', component: ResourcesEndTimes, meta: { name: 'Resources | End Times', requiresAuth: true }},
            ]
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});

export default router
