<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white" id="navigation-example">
      <div class="container">
        <div class="navbar-wrapper">
          <a class="navbar-brand" href="#pablo">Login Page</a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" data-target="#navigation-example">
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
        </button>
      </div>
    </nav>
    <!-- End Navbar -->
    <div class="wrapper wrapper-full-page">
      <div class="page-header login-page header-filter" filter-color="black" style="background-image: url('../assets/img/login.jpg'); background-size: cover; background-position: top center;">
        <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
        <div class="container" id="app">
          <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
            <div class="card card-login card-hidden">
              <div class="card-header card-header-rose text-center">
                <h4 class="card-title">Login</h4>
              </div>
              <div class="card-body ">
                <p class="card-description text-center">Or Be Classical</p>
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">face</i>
                      </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Username..." v-model="username">
                  </div>
                </span>
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password" class="form-control" placeholder="Password..." v-model="password">
                  </div>
                </span>
              </div>
              <div class="card-footer justify-content-center">
                <div class="btn btn-rose btn-link  btn-lg" @click="login">Lets Go</div>
              </div>
            </div>
          </div>
        </div>
        <footer />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Alert from "@/components/alert";
import Footer from "@/components/footer";
export default {
  name: "Login",
  components: {Footer, Alert},
  data:function(){
    return{
      year: new Date().getFullYear(),
      username: '',
      password: '',
      message: '',
      type: '',
    }
  },
  mounted() {
    $(document).ready(function() {
      demo.checkFullPageBackgroundImage();
      setTimeout(function() {
        // after 1000 ms we add the class animated to the login/register card
        $('.card').removeClass('card-hidden');
      }, 700);
    });
  },
  methods: {
    ...mapActions(["LogIn"]),

    async login () {
      let app = this;
      let payload = {
        username: app.username,
        password: app.password,
      };

      let response = await this.LogIn(payload);
      if (response.status === 200){
        await this.$router.push({name: 'blog'})
      }
      else {
        swal("Error!", response.message, "error");
      }
    },
  }
}
</script>
<style>
.swal2-container {
  display: flex !important;
}
</style>
