<template>
  <div class="sidebar" data-color="rose" data-background-color="black" data-image="/assets/img/sidebar-1.jpg">
    <div class="logo">
      <a href="https://covenantnet.com" class="simple-text logo-mini">
        cn
      </a>
      <a href="https://dash.covenantnet.com" class="simple-text logo-normal">
        covenantnet
      </a>
    </div>
    <div class="sidebar-wrapper">
      <div class="user">
        <div class="photo">
          <img src="https://via.placeholder.com/150" />
        </div>
        <div class="user-info">
          <router-link data-toggle="collapse" :to="{ name: 'blog'}" class="username">
              <span id="userName">{{ this.$store.state.user.fullName }}</span>
          </router-link>
        </div>
      </div>
      <ul class="nav">
        <li class="nav-item" :class="{'active': this.$route.name === 'blog'}">
          <router-link class="nav-link" :to="{ name: 'blog'}">
            <i class="material-icons">image</i>
            <p> Blog Page </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'about'}">
          <router-link class="nav-link" :to="{ name: 'about'}">
            <i class="material-icons">image</i>
            <p> About Page </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'magazine'}">
          <router-link class="nav-link" :to="{ name: 'magazine'}">
            <i class="material-icons">image</i>
            <p> Magazine Page </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'people'}">
          <router-link class="nav-link" :to="{ name: 'people'}">
            <i class="material-icons">image</i>
            <p> People </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'project'}">
          <router-link class="nav-link" :to="{ name: 'project'}">
            <i class="material-icons">image</i>
            <p> Project </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'testimonies'}">
          <router-link class="nav-link" :to="{ name: 'testimonies'}">
            <i class="material-icons">image</i>
            <p> Testimonies </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'ministries'}">
          <router-link class="nav-link" :to="{ name: 'ministries'}">
            <i class="material-icons">image</i>
            <p> Ministries </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'training'}">
          <router-link class="nav-link" :to="{ name: 'training'}">
            <i class="material-icons">image</i>
            <p> Training </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'resources'}">
          <router-link class="nav-link" :to="{ name: 'resources'}">
            <i class="material-icons">image</i>
            <p> Resources </p>
          </router-link>
        </li>
        <!--
                <li class="nav-item" :class="{'active': this.$route.name === 'mailing'}">
                  <router-link class="nav-link" :to="{ name: 'mailing'}">
                    <i class="material-icons">image</i>
                    <p> Mailing List </p>
                  </router-link>
                </li>
                <li class="nav-item" :class="{'active': this.$route.name === 'donate'}">
                  <router-link class="nav-link" :to="{ name: 'donate'}">
                    <i class="material-icons">image</i>
                    <p> Donation </p>
                  </router-link>
                </li>
                -->
        <li class="nav-item" :class="{'active': this.$route.name === 'registration'}">
          <router-link class="nav-link" :to="{ name: 'registration'}">
            <i class="material-icons">book</i>
            <p> Registration Management </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'OnlineBibleStudy'}">
          <router-link class="nav-link" :to="{ name: 'OnlineBibleStudy'}">
            <i class="material-icons">image</i>
            <p> Online | Bible Study </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'OnlineBusiness'}">
          <router-link class="nav-link" :to="{ name: 'OnlineBusiness'}">
            <i class="material-icons">image</i>
            <p> Online | Business Management </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'OnlineCourseStudy'}">
          <router-link class="nav-link" :to="{ name: 'OnlineCourseStudy'}">
            <i class="material-icons">image</i>
            <p> Online | Course Study </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'OnlineCreativity'}">
          <router-link class="nav-link" :to="{ name: 'OnlineCreativity'}">
            <i class="material-icons">image</i>
            <p> Online | Creativity </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'OnlineLeadership'}">
          <router-link class="nav-link" :to="{ name: 'OnlineLeadership'}">
            <i class="material-icons">image</i>
            <p> Online | Leadership </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'OnlineRegistration'}">
          <router-link class="nav-link" :to="{ name: 'OnlineRegistration'}">
            <i class="material-icons">image</i>
            <p> Online | Registration </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'settings'}">
          <router-link class="nav-link" :to="{ name: 'settings'}">
            <i class="material-icons">manage_accounts</i>
            <p> Settings </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'preferences'}">
          <router-link class="nav-link" :to="{ name: 'preferences'}">
            <i class="material-icons">room_preferences</i>
            <p> Preferences </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'comments'}">
          <router-link class="nav-link" :to="{ name: 'comments'}">
            <i class="material-icons">comment</i>
            <p> Comments </p>
          </router-link>
        </li>
        <li class="nav-item" :class="{'active': this.$route.name === 'files'}">
          <router-link class="nav-link" :to="{ name: 'files'}">
            <i class="material-icons">cloud</i>
            <p> Files </p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "side-bar",
  methods: {
    ...mapActions(["LogOut"]),
    async logout(){
      await this.LogOut();
    }
  }
}
</script>

<style scoped>

</style>
